import {useRouter} from 'next/router'
import {memo, useState, useEffect} from 'react'

import {useAtom} from 'jotai'
import {UserState} from '@states/User'

import {Menu} from '@headlessui/react'
import {Float} from '@headlessui-float/react'
import {size} from '@floating-ui/react-dom'

import {Link} from '@ui/Modules'

import styles from './HeaderMenu.module.css'

const basicMenu = [
	{
		title: 'Cards',
		slug: '/certificates',
	},
	{
		title: 'Projects',
		slug: '/projects',
	},
	{
		title: 'Brands',
		slug: '/brands',
	},
]

const adminMenu = [
	{
		title: 'Pages',
		submenu: [
			{title: 'Certificates', slug: '/manager/certificates'},
			{title: 'Collections', slug: '/manager/collections'},
			{title: 'Projects', slug: '/manager/projects'},
			{title: 'Brands & Partners', slug: '/manager/brands'},
			{title: 'Posts', slug: '/manager/posts'},
			{title: 'Registries', slug: '/manager/registries'},
		],
	},
	{
		title: 'Shop',
		submenu: [
			{title: 'Orders', slug: '/manager/orders'},
			{title: 'Gifts', slug: '/manager/gifts'},
			{title: 'Rewards', slug: '/manager/rewards'},
			{title: 'Express Checkout', slug: '/manager/proposals'},
		],
		total: '0',
	},
	{
		title: 'Users',
		slug: '/manager/users',
	},
	{
		title: 'Requests',
		submenu: [
			{title: 'Tech support', slug: '/manager/support'},
			{title: 'Reports', slug: '/manager/reports'},
		],
	},
	{
		title: 'Other',
		submenu: [
			{title: 'Banners', slug: '/manager/banners'},
			{title: 'Lexicons', slug: '/manager/lexicons'},
		],
	},
	{
		title: 'System',
		submenu: [
			{title: 'API Keys', slug: '/manager/api/keys'},
			{title: 'Notifications', slug: '/manager/notifications'},
			{title: 'Logs', slug: '/manager/logs'},
		],
	},
]

const HeaderMenu = () => {
	const [user, setUser] = useAtom(UserState)
	const [menu, setMenu] = useState([])

	const router = useRouter()

	useEffect(() => {
		let menuItems = []
		menuItems.push(...basicMenu)

		if (user && user['admin'] && router.pathname.includes('/manager')) {
			menuItems.push(...adminMenu)
		}
		setMenu(menuItems)
	}, [user, router])

	return (
		<Menu>
			{({open}) => (
				<Float
					className="tw-z-20 tw-flex tw-items-center"
					placement="bottom-end"
					offset={{mainAxis: 20, crossAxis: 0}}
					strategy="absolute"
					flip
					enter="tw-transition tw-ease-out tw-duration-100"
					enterFrom="tw-opacity-0"
					enterTo="tw-opacity-100"
					leave="tw-transition tw-ease-in tw-duration-100"
					leaveFrom="tw-opacity-100"
					leaveTo="tw-opacity-0"
					middleware={() => [
						size({
							apply({availableWidth, availableHeight, elements}) {
								Object.assign(elements.floating.style, {
									maxWidth: availableWidth + 'px',
								})

								if (elements.floating.childNodes.length > 0) {
									elements.floating.childNodes[0].style.maxWidth = availableWidth + 'px'
								}
							},
						}),
					]}
				>
					<Menu.Button as="button" className="ui-menu ui-menu-primary tw-flex tw-h-[18px] tw-w-[24px] tw-cursor-pointer tw-flex-col tw-items-center  tw-justify-between" tabIndex={1}>
						<span className={`${styles.line} ${open ? `${styles.open}` : ''}`}></span>
						<span className={`${styles.line} ${open ? `${styles.open}` : ''}`}></span>
						<span className={`${styles.line} ${open ? `${styles.open}` : ''}`}></span>
					</Menu.Button>
					<Menu.Items as="ul" className="ui-menu-items ui-arrow ui-arrow-secondary ui-arrow-top-right tw-text-[14px]">
						{menu.map((item, index) => (
							<div key={index}>
								{item['slug'] && (
									<Menu.Item key={index} as="li">
										{({active}) => (
											<Link
												href={item['slug']}
												itemProp="url"
												className={
													'ui-menu-item tw-flex tw-w-full tw-items-start tw-justify-between' + (router.pathname == item['slug'] || active ? ' ui-menu-item-active' : '')
												}
												tabIndex={1}
											>
												{item['title']}

												{item['total'] && <span className="tw-text-on-surface-1">{item['total']}</span>}
											</Link>
										)}
									</Menu.Item>
								)}
								{item['submenu'] &&
									item.submenu.map((subItem, subIndex) => (
										<Menu.Item key={subIndex} as="li">
											{({active}) => (
												<Link
													href={subItem['slug']}
													itemProp="url"
													className={
														'ui-menu-item tw-flex tw-w-full tw-items-start tw-justify-between' +
														(router.pathname == subItem['slug'] || active ? ' ui-menu-item-active' : '')
													}
													tabIndex={1}
												>
													{subItem['title']}

													{subItem['total'] && <span className="tw-text-on-surface-1">{subItem['total']}</span>}
												</Link>
											)}
										</Menu.Item>
									))}
							</div>
						))}
					</Menu.Items>
				</Float>
			)}
		</Menu>
	)
}

export default memo(HeaderMenu)
