import {fetchWrapper, removeCookies, setCookies} from '@helpers/functions'

import {amplitudeTrackEvent} from '@hooks/useAmplitude'
import {dayjs} from '@hooks/useDate'

const setUserCookies = (headers, userData = null, ctx = null) => {
	const date = dayjs(headers.get('Expire-At')).toDate()

	const userCookies = {
		access_token: headers.get('Access-Token'),
		access_token_expire_at: headers.get('Expire-At'),
		refresh_token: headers.get('Refresh-Token'),
		...(userData && {'user.data': userData}),
	}

	Object.entries(userCookies).forEach(([key, value]) => setCookies(key, value, date, ctx))
}

const removeUserCookies = (ctx = null) => {
	const userCookies = ['access_token', 'access_token_expire_at', 'refresh_token', 'user.data']

	userCookies.forEach((key) => removeCookies(key, ctx, {path: '/'}))
}

export async function authUser(body, ctx = null, options = {}) {
	const response = await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/sign_in', ctx, {
		body: JSON.stringify(body),
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		mode: 'cors',
		...options,
	})

	if (response['success']) {
		setUserCookies(response['headers'], JSON.stringify(response['body']))
	}

	return response
}

export async function signupUser(body, ctx = null, options = {}) {
	const response = await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/sign_up', ctx, {
		body: JSON.stringify(body),
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		mode: 'cors',
		...options,
	})

	if (response['success']) {
		setUserCookies(response['headers'], JSON.stringify(response['body']))
	}

	return response
}

export async function expressSignupUser(body, ctx = null, options = {}) {
	const response = await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/sign_up/express', ctx, {
		body: JSON.stringify(body),
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		mode: 'cors',
		...options,
	})

	if (response['success']) {
		setUserCookies(response['headers'], JSON.stringify(response['body']))
	}

	return response
}

export async function logoutUser(reload = true, ctx = null) {
	amplitudeTrackEvent('SIGN_OUT')

	removeUserCookies(ctx)

	if (!ctx && reload && typeof window !== 'undefined') { 
		window.location.reload()
	} else {
		return true
	}
}

export async function sendResetPasswordLink(body, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/user/forgot_password', ctx, {
		body: JSON.stringify(body),
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		mode: 'cors',
		...options,
	})
}

export async function updateUserPassword(body, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/user/update_password', ctx, {
		body: JSON.stringify(body),
		headers: {'Content-Type': 'application/json'},
		method: 'PUT',
		...options,
	})
}

export async function signWert({tokenId, params, path, voucherType = 'wert_voucher', ctx = null, options = {}}) {
	let url = process.env.NEXT_PUBLIC_API_URL + '/api/' + path + '/' + tokenId + '/' + voucherType

	if (params?.count) {
		url += `?count=${params.count}`
	}

	return await fetchWrapper(url, ctx, options)
}

export async function getNonce(address, ctx = null, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/eth_address_nonces/' + address, ctx, {
		headers: {'Content-Type': 'application/json'},
		method: 'GET',
		...options,
	})
}
export async function signInMetmask(body, ctx = null, options = {}) {
	const response = await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/sign_in/by_eth_public_address', ctx, {
		body: JSON.stringify(body),
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		...options,
	})

	if (response['success']) {
		setUserCookies(response['headers'], JSON.stringify(response['body']))
	}

	return response
}

export async function signByGoogle(type, ctx = null, options = {}) {
	const response = await fetchWrapper('/api/auth/google/', ctx, {
		body: JSON.stringify({type}),
		method: 'POST',
		...options,
	})

	if (response['success']) {
		window.location.href = response['body']['authorization_uri']
	}
}

export async function signUserByGoogle(type, body, ctx = null, options = {}) {
	const response = await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/' + type + '/by_google', ctx, {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
		body: JSON.stringify(body),
		mode: 'cors',
		...options,
	})

	amplitudeTrackEvent(response['success'] ? 'SIGN_IN_SUCCESS' : 'SIGN_IN_FAIL')

	return response
}

export async function remoteAuthUser(signature, ctx, options = {}) {
	const response = await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/api/users/sign_in/by_magick_link', ctx, {
		body: JSON.stringify({sig: signature}),
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		...options,
	})

	if (response['success']) {
		setUserCookies(response['headers'], JSON.stringify(response['body']), ctx)
	}

	return response
}
