import {useRouter} from 'next/router'
import {memo, useState, useEffect} from 'react'

import {logoutUser} from '@actions/_app/auth'

import {useAtom} from 'jotai'
import {UserState} from '@states/User'

import {Menu} from '@headlessui/react'
import {Float} from '@headlessui-float/react'
import {size} from '@floating-ui/react-dom'

import {Avatar} from '@ui/Blocks'
import {Link} from '@ui/Modules'

const UserMenu = () => {
	const [user, setUser] = useAtom(UserState)
	const [menu, setMenu] = useState([])

	const router = useRouter()

	useEffect(() => {
		if (user) {
			let menuItems = [
				{
					title: 'My Profile',
					slug: '/users/' + (user['nickname']?.length > 0 ? user['nickname'] : user['id']),
				},
				{
					title: 'Settings',
					slug: '/profile/edit',
				},
				{
					title: 'Log out',
					onClick: logoutHandler,
				},
			]

			if (user['roles']?.length > 0) {
				menuItems.unshift({
					title: 'Console',
					slug: user['roles'].length === 1 ? '/b-manager/' + user['roles'][0]['brand']['id'] : '/b-manager/',
				})
			}

			if (user['admin']) {
				menuItems.unshift({
					title: 'Admin Panel',
					slug: '/manager/dashboard',
				})
			}

			setMenu(menuItems)
		}
	}, [user])

	const logoutHandler = async () => {
		await logoutUser()
	}

	return (
		<Menu>
			<Float
				className="tw-z-20 tw-flex tw-items-center"
				placement="bottom"
				offset={{mainAxis: 20}}
				strategy="absolute"
				flip
				enter="tw-transition tw-ease-out tw-duration-100"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-transition tw-ease-in tw-duration-100"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				middleware={() => [
					size({
						apply({availableWidth, availableHeight, elements}) {
							Object.assign(elements.floating.style, {
								maxWidth: availableWidth + 'px',
							})

							if (elements.floating.childNodes.length > 0) {
								elements.floating.childNodes[0].style.maxWidth = availableWidth + 'px'
							}
						},
					}),
				]}
			>
				<Menu.Button as="button" className="ui-menu ui-menu-primary" tabIndex="1">
					<Avatar
						srcset={{
							'2x': user?.['avatar_links']?.['96x96'] ? user['avatar_links']['96x96'] : '/assets/images/pages/users/avatars/96x96.jpg',
							'1x': user?.['avatar_links']?.['48x48'] ? user['avatar_links']['48x48'] : '/assets/images/pages/users/avatars/48x48.jpg',
						}}
						src={user?.['avatar_links']?.['48x48'] ? user['avatar_links']['48x48'] : '/assets/images/pages/users/avatars/48x48.jpg'}
						className="ui-avatar-primary tw-h-[48px] tw-w-[48px] tw-border-2"
						alt={user?.['nickname']}
					/>
				</Menu.Button>
				<Menu.Items as="ul" className="ui-menu-items ui-arrow ui-arrow-secondary ui-arrow-top tw-text-[14px]">
					{/*TODO: не работает определение router.pathname c динамичным квери, а asPath пробрасывает лишние квери - переделать в будущем*/}
					{menu.map((item) => (
						<Menu.Item key={item['title']} as="li">
							{({active}) => (
								<>
									{item['slug'] && (
										<Link
											href={item['slug']}
											itemProp="url"
											className={'ui-menu-item tw-flex tw-w-full tw-items-start tw-justify-between' + (router.pathname == item['slug'] || active ? ' ui-menu-item-active' : '')}
											tabIndex={1}
										>
											{item['title']}

											{item['total'] && <span className="tw-text-on-surface-1">{item['total']}</span>}
										</Link>
									)}
									{item['onClick'] && (
										<a
											onClick={item['onClick']}
											className={'ui-menu-item tw-flex tw-w-full tw-grow tw-cursor-pointer tw-items-start tw-justify-between' + (active ? ' ui-menu-item-active' : '')}
											tabIndex={1}
										>
											{item['title']}

											{item['total'] && <span className="tw-text-on-surface-1">{item['total']}</span>}
										</a>
									)}
								</>
							)}
						</Menu.Item>
					))}
				</Menu.Items>
			</Float>
		</Menu>
	)
}

export default memo(UserMenu)
